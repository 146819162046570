import Button from "../../components/button";
import Card from "../../components/card";
import Header from "../../components/header";
import Footer from "../../components/footer";

import {
  Container,
  ContainerImage,
  ContainerYourApartment,
  ImageInitial,
  ContentYourApartment,
  TextsApartment,
  Infos,
  Obs,
  ContentImage,
  ImageIlustratrion,
  ContainerFindPropertie,
  ContentFindPropertie,
  ContentInfos,
  Title,
  Subtitle,
  ContainerContact,
  ContentContact,
  TitleContact,
  InfosContact,
  ContainerRegister,
  ContentRegister,
  TitleRegister,
  ContainerCards,
  ContainerWhoUs,
  ContentWhoUs,
  TitleWhoUs,
  ContentInfosWhoUs,
  TextWhoUs,
  ContentImageWhoUs,
  ImageWhoUs,
  InfosWhoUs,
  InfosWhoUsEmphasis,
  TextWaiting,
  ContentWhatsApp,
  ContentFooter,
} from "./styles";

import ImageSectionApartment from "../../assets/foto1.png";
import ImageSectionFindPropertie from "../../assets/foto2.png";
import ImageSectionWhoUs from "../../assets/foto3.png";
import IconHome from "../../assets/icon_casa.png";
import IconSimulation from "../../assets/icon_simula.png";
import IconPhone from "../../assets/icon_tel.png";
import IconAvaliation from "../../assets/icon_avalia.png";
import IconNewLife from "../../assets/icon_vida.png";
import IconSimulationClock from "../../assets/icon_simula_relogio.png";
import { FaWhatsapp } from "react-icons/fa";
import ImageBackground from "../../assets/header.png";

export const Home = () => {
  return (
    <Container>
      <Header />
      <ContainerImage>
        <ImageInitial src={ImageBackground} />
      </ContainerImage>
      <ContainerYourApartment>
        <ContentYourApartment>
          <TextsApartment>
            <Infos>Seu apartamento novo está aqui</Infos>
            <Infos>Renda Familiar R$ 2.500,00* </Infos>
            <Infos>Crédito aprovado em no máximo 24 horas</Infos>
            <Obs>Mediante comprovação. Consulte documentos necessários.</Obs>
          </TextsApartment>
          <ContentImage>
            <ImageIlustratrion src={ImageSectionApartment} />
          </ContentImage>
        </ContentYourApartment>
      </ContainerYourApartment>
      <ContainerFindPropertie>
        <ContentFindPropertie>
          <ContentImage>
            <ImageIlustratrion src={ImageSectionFindPropertie} />
          </ContentImage>
          <ContentInfos>
            <Title>Nós vamos te ajudar</Title>
            <Subtitle>Onde você quer morar?</Subtitle>
            <Button backgroundColor="#ff4900" fontSize={17} icon={IconHome}>
              Procure o seu futuro lar
            </Button>
            <Button
              backgroundColor="#ff4900"
              fontSize={17}
              icon={IconSimulation}
            >
              Faça uma simulação
            </Button>
          </ContentInfos>
        </ContentFindPropertie>
      </ContainerFindPropertie>
      <ContainerContact>
        <ContentContact>
          <TitleContact>Entre em contato</TitleContact>
          <InfosContact>
            <Button href="https://wa.me/5511983344053" widthIcon={50}>
              WhatsApp
            </Button>
            <Button icon={IconPhone} widthIcon={50}>
              Telefone
            </Button>
          </InfosContact>
        </ContentContact>
      </ContainerContact>
      <ContainerRegister>
        <ContentRegister>
          <TitleRegister>
            Veja como fazer seu cadastro e uma simulação
          </TitleRegister>
          <ContainerCards>
            <Card title="INFORMAÇÕES">
              Dados sobre o seu perfil e como pretende financiar o seu
              apartamento.
            </Card>
            <Card title="SIMULAÇÃO" icon={IconSimulationClock}>
              Analisamos essas informações e, a partir delas, calculamos
              projeções de condições do seu financiamento.
            </Card>
            <Card title="ANÁLISE" icon={IconAvaliation}>
              Escolha seu apartamento que te ajudamos com a documentação
              necessária para análise de Caixa Econômica Federal.
            </Card>
            <Card title="VIDA NOVA" icon={IconNewLife}>
              Com tudo certo e aprovado na Caixa Econômica Federal, você assina
              o contrato e é só aguardar as chaves do seu novo lar!
            </Card>
          </ContainerCards>
        </ContentRegister>
      </ContainerRegister>
      <ContainerWhoUs>
        <ContentWhoUs>
          <TitleWhoUs>QUEM SOMOS</TitleWhoUs>
          <ContentInfosWhoUs>
            <TextWhoUs>
              <InfosWhoUs>
                A maior plataforma de imóveis econômicos (Casa verde e Amarela)
                novos, em construção ou prontos, na Capital e Grande São Paulo
              </InfosWhoUs>
              <InfosWhoUs>
                Nós somos parceiros de todas as incorporadoras de São Paulo, e
                sabe o que isso quer dizer? Nós temos o seu imóvel. No lugar que
                vc procura, com a sua cara e com condições que cabem no seu
                bolso.
              </InfosWhoUs>
              <InfosWhoUsEmphasis>
                Venha Realizar o sonho do seu novo lar. Venha para Urblar!
              </InfosWhoUsEmphasis>
              <InfosWhoUsEmphasis>
                Atendimento online e visita agendada.
              </InfosWhoUsEmphasis>
              <TextWaiting>Estamos te esperando!</TextWaiting>
              <ContentWhatsApp
                href="https://wa.me/5511983344053"
                target="__blank"
              >
                <FaWhatsapp size={30} />
                (11) 98334-4053
              </ContentWhatsApp>
            </TextWhoUs>
            <ContentImageWhoUs>
              <ImageWhoUs src={ImageSectionWhoUs} />
            </ContentImageWhoUs>
          </ContentInfosWhoUs>
        </ContentWhoUs>
      </ContainerWhoUs>
      <ContentFooter>
        <Footer />
      </ContentFooter>
    </Container>
  );
};

export default Home;

import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ContainerImage = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 556px;
  @media (max-width: 950px) {
    height: 100%;
  }
`;

export const ImageInitial = styled.img`
  width: 950px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2px;
  @media (max-width: 950px) {
    width: 100%;
    height: 100%;
  }
`;

export const ContainerYourApartment = styled.section`
  width: 100%;
  height: 361px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 975px) {
    height: 100%;
    margin-top: 0px;
  }
`;

export const ContentYourApartment = styled.div`
  width: 950px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 975px) {
    flex-direction: column;
  }
`;

export const TextsApartment = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 20px;
  @media (max-width: 975px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  @media (max-width: 670px) {
    padding: 20px 20px;
  }
`;

export const Infos = styled.label`
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  color: #ff4900;
  font-weight: bold;
`;

export const Obs = styled.label`
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
`;

export const ContentImage = styled.figure`
  width: 609px;
  height: 100%;
  @media (max-width: 975px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImageIlustratrion = styled.img`
  height: 100%;
  width: 100%;
  @media (max-width: 975px) {
    width: 609px;
  }
  @media (max-width: 630px) {
    width: 100%;
    height: 100%;
  }
`;

export const ContainerFindPropertie = styled.section`
  width: 100%;
  height: 361px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 975px) {
    height: 100%;
  }
`;

export const ContentFindPropertie = styled.div`
  width: 950px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 975px) {
    flex-direction: column;
  }
`;

export const ContentInfos = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 975px) {
    margin-top: 25px;
  }
`;

export const Title = styled.h1`
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  color: #ff4900;
  font-weight: bold;
`;

export const Subtitle = styled.h3`
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #727171;
`;

export const ContainerContact = styled.section`
  width: 100%;
  height: 200px;
  background-color: #283d43;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 975px) {
    padding: 20px 20px;
  }
  @media (max-width: 730px) {
    height: 100%;
  }
`;

export const ContentContact = styled.div`
  width: 950px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 730px) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const TitleContact = styled.h1`
  font-size: 40px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
`;

export const InfosContact = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export const ContainerRegister = styled.section`
  height: 500px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  margin-top: 120px;
  @media (max-width: 975px) {
    height: 100%;
  }
`;

export const ContentRegister = styled.div`
  width: 950px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleRegister = styled.h1`
  color: #283d43;
  font-size: 38px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  margin-bottom: 70px;
  @media (max-width: 975px) {
    padding: 20px 20px;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  @media (max-width: 975px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    justify-items: center;
  }
  @media (max-width: 630px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerWhoUs = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6ffe2;
  margin-top: 100px;
  padding-bottom: 35px;
  padding-top: 35px;
`;

export const ContentWhoUs = styled.div`
  height: 100%;
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 975px) {
    padding: 20px 20px;
  }
`;

export const TitleWhoUs = styled.h1`
  font-size: 31px;
  color: #283d43;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
`;

export const ContentInfosWhoUs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 975px) {
    flex-direction: column;
  }
`;

export const TextWhoUs = styled.div`
  height: 418px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-right: 25px;
`;

export const InfosWhoUs = styled.p`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #283d43;
`;

export const InfosWhoUsEmphasis = styled.p`
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #ff4900;
  font-weight: bold;
`;

export const ContentImageWhoUs = styled.figure``;

export const ImageWhoUs = styled.img`
  width: 585px;
  height: 418px;
  @media (max-width: 630px) {
    width: 100%;
    height: 100%;
  }
`;

export const TextWaiting = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #283d43;
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
`;

export const ContentWhatsApp = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #283d43;
  font-family: "Montserrat", sans-serif;
  gap: 10px;
  margin-top: 10px;
  text-decoration: none;
`;

export const ContentFooter = styled.div`
  width: 100%;
`;

import styled from "styled-components";

export const Container = styled.div`
  background-color: #ff4900;
  width: 300px;
  height: 370px;
  border-top-left-radius: 29px;
  border-bottom-right-radius: 20px;
  padding: 25px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const ContentIcon = styled.figure`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  height: 120px;
`;

export const Icon = styled.img`
  height: 100%;
`;

export const TitleAndDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.p`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
`;

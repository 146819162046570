import {
  Container,
  Content,
  ContentIcon,
  Description,
  Icon,
  Title,
  TitleAndDescription,
} from "./styles";

import IconInfo from "../../assets/icon_info.png";

const Card = ({ icon = IconInfo, title, children }) => {
  return (
    <Container>
      <Content>
        <ContentIcon>
          <Icon src={icon} />
        </ContentIcon>
        <TitleAndDescription>
          <Title>{title}</Title>
          <Description>{children}</Description>
        </TitleAndDescription>
      </Content>
    </Container>
  );
};

export default Card;

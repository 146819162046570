import styled from "styled-components";

export const Container = styled.a`
  width: 250px;
  height: 70px;
  border-top-left-radius: 29px;
  border-bottom-right-radius: 29px;
  background-color: #16b404;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ContentIcon = styled.figure`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const Icon = styled.img``;

export const Title = styled.h1`
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
`;

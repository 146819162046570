import { Container, Content, ContentIcon, Icon, Title } from "./styles";

import IconWhatsApp from "../../assets/icon_whats.png";

const Button = ({
  icon = IconWhatsApp,
  children,
  width,
  height,
  fontSize,
  backgroundColor,
  href,
  widthIcon,
  heightIcon,
}) => {
  return (
    <Container
      style={{ width: width, height: height, backgroundColor: backgroundColor }}
      href={href}
      target="__blank"
    >
      <Content>
        <ContentIcon>
          <Icon src={icon} style={{ width: widthIcon, height: heightIcon }} />
        </ContentIcon>
        <Title style={{ fontSize: fontSize }}>{children}</Title>
      </Content>
    </Container>
  );
};

export default Button;

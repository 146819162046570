import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 550px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 950px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2px;
  @media (max-width: 950px) {
    width: 100%;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    height: 100%;
    gap: 20px;
  }
  padding: 20px 20px;
`;

export const ContentLogo = styled.figure`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 317px;
  height: 76px;
`;

export const ContentChat = styled.a`
  border: none;
  cursor: pointer;
  background-color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconChat = styled.img`
  width: 157px;
  height: 86px;
`;

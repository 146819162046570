import {
  Container,
  Content,
  ContentChat,
  ContentLogo,
  IconChat,
  Logo,
} from "./styles";

import LogoUrblar from "../../assets/logotipo_urblar.svg";
import ChatIcon from "../../assets/chat.png";

const Header = () => {
  return (
    <Container>
      <Content>
        <ContentLogo>
          <Logo src={LogoUrblar} />
        </ContentLogo>
        <ContentChat>
          <IconChat src={ChatIcon} />
        </ContentChat>
      </Content>
    </Container>
  );
};

export default Header;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./home";

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
      </Routes>
    </Router>
  );
};

export default Root;
